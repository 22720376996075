import React, { Component } from "react"
import Header from "../components/basics-compoment/Header"
import Footer from "../components/basics-compoment/Footer"
import "../myStyle.css"
import HelmetCompoment from "../components/basics-compoment/HelmetCompoment"
import ContactForm from "../components/contact-compoment/ContactForm"


// markup
class Contact extends Component {

  render () {

    return (
      <React.Fragment>

      <HelmetCompoment></HelmetCompoment>
      <Header page="contact"></Header>
      <ContactForm></ContactForm>

      <Footer></Footer>
      </React.Fragment>
    )

  }

}

export default Contact

