import React, { Component } from "react"
import "../../myStyle.css"
import "./ContactForm.css"

import CallToAction from "../style-compoment/CallToAction"
import TextStyle from "../style-compoment/TextStyle"
import { Link } from "gatsby"

import app from 'gatsby-plugin-firebase-v9.0'
import { getDatabase, ref, push } from "firebase/database";
import check from '../../images/general-icon/check.png'


class ContactForm extends Component {

    state = {
        firstName: "",
        name: "",
        mail: "",
        message: "",
        errorFrench: "",
        errorEnglish: "",
        errorSpanish: "",
        validationEmail: true,
        recordedData: false,
        newsletter: false,
        recordedDataClass: "contactForm-checkbox-container",
        newsletterClass: "contactForm-checkbox-container",
        recordedDataCheck: "contactForm-check",
        newsletterCheck: "contactForm-check"
    }



    handleChangeFirstName(event) {
        console.log(event.target.value)
        this.setState({
            firstName: event.target.value
        });
    }

    handleChangeName(event) {
        this.setState({
            name: event.target.value});
    }

    handleChangeMail(event) {
        this.setState({
            mail: event.target.value});
    }

    handleChangeMessage(event) {
        this.setState({
            message: event.target.value});
    }

    handleRecordedData() {
            this.setState({
                recordedData: !this.state.recordedData,
            });
            if (this.state.recordedData === false) {
                this.setState({
                    recordedDataClass: "contactForm-checkbox-container contactForm-checkbox-true",
                    recordedDataCheck: "contactForm-check contactForm-check-true"
                });
            } else {
                this.setState({
                    recordedDataClass: "contactForm-checkbox-container",
                    recordedDataCheck: "contactForm-check"
                });
            }
    }



    handleSendNewsletter() {
        this.setState({
            newsletter: !this.state.newsletter
        });
        if (this.state.newsletter === false) {
            this.setState({
                newsletterClass: "contactForm-checkbox-container contactForm-checkbox-true",
                newsletterCheck: "contactForm-check contactForm-check-true"

            });
        } else {
            this.setState({
                newsletterClass: "contactForm-checkbox-container",
                newsletterCheck: "contactForm-check"

            });
        }
    }

    SendContactForm = () => {

        console.log(this.state.recordedData)

        const firstName = this.state.firstName
        const name = this.state.name
        const mail = this.state.mail
        const message = this.state.message
        const newsletter = this.state.newsletter

        this.setState({
            errorFrench: "",
            errorEnglish: "",
            errorSpanish: "",
            validationEmail: true
        })


            if ((firstName === "") || (name === "") || (mail === "") || (message === "")) {
                this.setState({
                    errorFrench: "Tous les champs doivent être remplis",
                    errorEnglish: "All fields must be completed",
                    errorSpanish: "Todos los campos deben ser completados",
                    validationEmail: false
                })
            } else {
                const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                if(!mail || regex.test(mail) === false){
                    this.setState({
                        errorFrench: "L'adresse mail n'est pas valide",
                        errorEnglish: "Email is not valid",
                        errorSpanish: "La dirección de correo electrónico es inválida",
                        validationEmail: false
                    });
                } else {
                    if (this.state.recordedData === true) {

                    const db = getDatabase();
                    const reference = ref(db, 'users/');
                    push(reference, {
                        firstName: firstName,
                        name: name,
                        mail: mail,
                        message: message,
                        newsletter: newsletter
                    }).then(() => {
                        this.setState({
                            errorFrench: "Votre message a bien été envoyé",
                            errorEnglish: "Your message has been sent",
                            errorSpanish: "Tu messaje ha sido enviado",
                            firstName: "",
                            name: "",
                            mail: "",
                            message: ""
                        });
                    }).catch(() => {
                        this.setState({
                            errorFrench: "Un problème est survenu, veuillez réssayer plus tard",
                            errorEnglish: "Something went wrong, please try again later",
                            errorSpanish: "Algo salió mal, inténtalo de nuevo más tarde",
                            validationEmail: false
                        });
                    })

                    } else {
                        this.setState({
                            errorFrench: "Vous n'autorisez pas l'enregistrement de vos données",
                            errorEnglish: "You do not authorize the recording of your data",
                            errorSpanish: "No autorizas el registro de tus datos",
                            validationEmail: false
                        });
                    }
                
                }
            }
    

    }



    render () {
        let color = "var(--navyColor)"
        if (this.state.validationEmail === false) {
            color = "red"
        }

        return (    
            <div className="ContactForm-container">
                <TextStyle
                    class="small"
                    french="32 Bd du Port, 95000 Cergy"
                    english="32 Bd du Port, 95000 Cergy"
                    spanish="32 Bd du Port, 95000 Cergy"
                    cssStyle={{paddingTop: "30px"}}
                ></TextStyle>
                <TextStyle
                    class="small"
                    french="contact@kodness.com"
                    english="contact@kodness.com"
                    spanish="contact@kodness.com"
                    cssStyle={{marginTop: "30px"}}
                ></TextStyle>
                <div className="ContactForm-second">
                    <div>
                        <TextStyle
                            class="small"
                            french="Prénom*"
                            english="First name*"
                            spanish="Primer nombre*"
                        ></TextStyle>
                        <input 
                            className="contact-input"                         
                            type="text"
                            value={this.state.firstName} 
                            onChange={e => this.handleChangeFirstName(e)}
                        ></input>
                    </div>
                    <div>
                        <TextStyle
                            class="small"
                            french="Nom*"
                            english="Name*"
                            spanish="Apellido*"
                        ></TextStyle>
                        <input 
                            className="contact-input"
                            type="text"
                            value={this.state.name} 
                            onChange={e => this.handleChangeName(e)}
                        ></input>
                    </div>

                    

                </div>
                <div className="ContactForm-email-container">
                    <TextStyle
                        class="small"
                        french="Adresse mail*"
                        english="Mail address*"
                        spanish="Correo electrónico*"
                    ></TextStyle>
                    <input 
                        className="contact-input contact-input-email"
                        type="email"
                        value={this.state.mail} 
                        onChange={e => this.handleChangeMail(e)}
                    ></input>
                </div>

                <div className="ContactForm-email-container">
                    <TextStyle
                        class="small"
                        french="Message*"
                        english="Message*"
                        spanish="Mensaje*"
                    ></TextStyle>
                    <textarea 
                        className="contact-input contact-input-email ContactForm-massage"
                        value={this.state.message} 
                        onChange={e => this.handleChangeMessage(e)}
                    ></textarea>
                </div>

                <TextStyle
                    class="small"
                    french={this.state.errorFrench}
                    english={this.state.errorEnglish}
                    spanish={this.state.errorSpanish}
                    cssStyle={{color: color}}
                ></TextStyle>
                
                <div className="contactForm-newsletter-container">

                    <div onClick={() => this.handleRecordedData()} className={this.state.recordedDataClass}>
                        <img className={this.state.recordedDataCheck} src={check} alt="true"></img>
                    </div>


                    <TextStyle
                        class="small contactForm-newsletter-text"
                        french={["J'autorise Kodness à enregistrer mes données ", <Link to="/legalNotice">(consulter nos CGU)</Link>]}
                        english={["I authorize Kodness to save my data ", <Link to="/legalNotice">(see our T&Cs)</Link>]}
                        spanish={["Autorizo a Kodness a guardar mis datos ", <Link to="/legalNotice">(ver nuestros T&C)</Link>]}
                        cssStyle={{marginLeft: "15px"}}
                    ></TextStyle>
                </div>
                <div className="contactForm-newsletter-container">

                    <div onClick={() => this.handleSendNewsletter()} className={this.state.newsletterClass}>
                        <img className={this.state.newsletterCheck} src={check} alt="true"></img>
                    </div>

                    <TextStyle
                        class="small contactForm-newsletter-text"
                        french="J’accepte de recevoir la newsletter de Kodness"
                        english="I agree to receive the Kodness newsletter"
                        spanish="Acepto recibir la newsletter de Kodness"
                        cssStyle={{marginLeft: "15px"}}
                    ></TextStyle>
                </div>


                <div className="contactForm-send-btn" onClick={this.SendContactForm}>
                    <CallToAction
                        french="Envoyer"
                        english="Send"
                        spanish="Enviar"
                        color="navy"
                    ></CallToAction>
                </div>

            </div>
        )

    }
}

export default ContactForm


